import { all, fork } from "redux-saga/effects";
import { clientAdminSaga } from "./client-admin-saga";
import { deleteClientAdminSaga } from "./delete-client-admin-saga";
import { deleteProjectAdminSaga } from "./delete-project-admin-saga";
import { deleteProjectManagementData } from "./delete-project-management-data";
import { deleteSettingTableDataSaga } from "./delete-setting-table-data";
import { deleteTimeSheetSaga } from "./delete-timesheet-saga";
import { allProjectManagementSaga } from "./get-all-project-management-saga";
import { allProjectsSaga } from "./get-all-projects-saga";
import { allUsersSaga } from "./get-all-user-saga";
import { assignedProjectsSaga } from "./get-assigned-projects-saga";
import { allClientsSaga } from "./get-client-saga";
import { detailedTimeSheetSaga } from "./get-detailed-timesheet-saga";
import { listItemsSaga } from "./get-list-items-saga";
import { mappedProjectManagementSaga } from "./get-mapped-project-management-data";
import { getMissingTimesheetsaga } from "./get-missing-timesheet-saga";
import { getNotificationssaga } from "./get-notifications-saga";
import { projectManagementSaga } from "./get-project-management-saga";
import { projectManagersSaga } from "./get-project-managers-saga";
import { projectTasksSaga } from "./get-project-tasks";
import { getReporteesSaga } from "./get-reportees-saga";
import { getSettingTableDataSaga } from "./get-setting-table-data";
import { getTimesheetProjectsSaga } from "./get-timesheet-projects";
import { timeSheetReporteeSaga } from "./get-timesheet-reportee-saga";
import { getTimesheetReportsSaga } from "./get-timesheet-reports";
import { getUnRegisteredUsersSaga } from "./get-unregistered-user-details-saga";
import { getRolesSaga } from "./get-user-roles-setting-saga";
import { myTimeSheetSaga } from "./my-timesheet-saga";
import { projectAdminSaga } from "./project-admin-saga";
import { projectAllocationSaga } from "./project-allocation-saga";
import { saveClientAdminFileSaga } from "./save-client-admin-file-saga";
import { saveClientAdminSaga } from "./save-client-admin-saga";
import { saveProjectAdminFileSaga } from "./save-project-admin-file-saga";
import { saveProjectAdminSaga } from "./save-project-admin-saga";
import { saveProjectManagementSaga } from "./save-project-management-data";
import { saveTimeSheetPeriodSaga } from "./save-timesheet-period-saga";
import { saveTimeSheetSaga } from "./save-timesheet-saga";
import { saveUserRoleSaga } from "./save-user-roles-setting-saga";
import { submitPeriodSaga } from "./submit-period-saga";
import { syncAzureDataSaga } from "./sync-azure-data-saga";
import { timeSheetSaga } from "./timesheet-saga";
import { updateClientAdminSaga } from "./update-client-admin-saga";
import { updateNotificationForUserSaga } from "./update-notification-for-user-saga";
import { updateNotificationsaga } from "./update-notification-saga";
import { updateProjectAdminSaga } from "./update-project-admin-saga";
import { updateProjectManagementSaga } from "./update-project-management-saga";
import { updateSettingTableDataSaga } from "./update-setting-table-user-role";
import { updateTimeSheetSaga } from "./update-timesheet-saga";
import { updateTimeSheetStatusSaga } from "./update-timesheet-status-saga";
import { allUserDetailSaga, userSaga } from "./user-saga";
import { getProjectByIdSaga } from "./get-project-by-id-saga";
import { getAllClientsInvoiceSaga } from "./get-invoice-tracker-data-saga";
import { saveInvoiceTrackerDataSaga } from "./save-client-invoice-saga";
import { getInvoiceSaga } from "./get-invoice-by-invoice-id-saga";
import { updateInvoiceSaga } from "./update-invoice-saga";
import { deleteInvoiceSaga } from "./delete-invoice-saga";
import { usersProjectAllocationSaga } from "./get-users-project-allocation";
import { getChangesHistorySaga } from "./get-changes-history-saga";
import { getInvoicedMonthsSaga } from "./get-invoiced-months-saga";
import { syncZohoPeopleSaga } from "./sync-zoho-people-saga";
import { getZohoPeopleDetailsSaga } from "./get-zoho-employee-saga";
import { skillsDataSaga } from "./get-skills-data";
import { downloadProjectAdminSaga } from "./download-project-admin-saga";
import {getAllocationChangeReportSaga} from "./get-allocation-change-report";
import { getMultipleFileViewSaga } from "./get-multiple-file-view-saga";
import { downloadMultipleFileViewSaga } from "./download-multiple-file-view-saga";
import { deleteMultipleFileViewSaga } from "./delete-multiple-file-view-saga";
import { holidayListSaga} from "./get-holiday-list-saga";
import { updateHolidaySaga } from "./update-holiday-saga";
import { deleteHolidaySaga } from "./delete-holiday-saga";
import { addHolidaySaga } from "./add-holiday-saga";
import { cloneHolidaySaga } from "./clone-holiday-saga";
import {setupWFODaysSaga} from "./get-wfo-days-saga";
import {updateWFODaysSaga} from "./update-wfo-days-saga";
import {addCustomWfoDaysSaga} from "./add-custom-wfo-days-saga";
import {getCustomWfoDaysSaga} from "./get-custom-wfo-days-saga";
import {cloneWfoDaysSaga} from "./clone-wfo-days-saga";
import {getAllocationChangeReportDataSaga } from "./get-allocation-change-report-data";
import {viewAttendanceSaga} from "./get-view-attendance-saga";
import {allLeaveDetailsSaga} from "./get-leave-details-saga";
import {getAllocationChangeReportByEmployeeSaga} from "./get-allocation-change-report-by-employee";
import {getAllocationChangeReportByEmployeeDataForExcelSaga} from "./get-allocation-change-report-by-employee-data-for-excel";
import {deleteCustomWfoDaySaga} from "./delete-custom-wfo-day-saga"
import { addSessionSaga } from "./add-session-saga";
import { updateSessionSaga } from "./update-session-saga";
import { allSessionsSaga } from "./get-all-session-saga";
import { deleteSessionSaga } from "./delete-session-saga";
import {participantsDataSaga} from "./get-participants-saga";
import {updateParticipantRequestSaga} from "./update-participant-request-saga";
import { registerUserSessionSaga } from "./register-user-session-training";
import { getUserDetailsForTrainingAndSessionSaga } from "./get-user-details-for-training-and-session-saga";
import { updateUserRegistrationSaga } from "./update-user-registration-saga";
import {sessionUserHistorySaga} from "./get-user-session-history-saga";
import {getSessionFeedbackQuestionsListSaga} from "./get-session-feedback-questions-list-saga";
import { updateAttendanceRequestSaga } from "./update-attendance-request-saga";
import {participantsDetailsBySessionSaga} from "./get-session-data-for-excel";
import {submitSessionFeedbackDetailsSaga} from "./submit-session-feedback-details-saga";
import {GetSessionFeedbackHeaderSaga} from "./get-session-feedback-header-saga";
import { sendMissingTimesheetReminderManuallySaga } from "./send-missing-timesheet-reminder-manually-saga";
import { getAttendanceForExcelSaga } from "./get-attendance-for-excel-saga";
import { clonePreviousWeekTimesheetSaga } from "./clone-previous-week-timesheet-saga";
export function* rootSaga() {
  yield all([
    fork(clientAdminSaga),
    fork(getReporteesSaga),
    fork(myTimeSheetSaga),
    fork(timeSheetSaga),
    fork(submitPeriodSaga),
    fork(saveClientAdminSaga),
    fork(saveTimeSheetSaga),
    fork(saveTimeSheetPeriodSaga),
    fork(updateClientAdminSaga),
    fork(updateTimeSheetSaga),
    fork(updateTimeSheetStatusSaga),
    fork(deleteTimeSheetSaga),
    fork(deleteClientAdminSaga),
    fork(userSaga),
    fork(projectAdminSaga),
    fork(saveProjectAdminSaga),
    fork(updateProjectAdminSaga),
    fork(deleteProjectAdminSaga),
    fork(listItemsSaga),
    fork(allClientsSaga),
    fork(projectManagersSaga),
    fork(allUsersSaga),
    fork(projectAllocationSaga),
    fork(allUserDetailSaga),
    fork(allProjectManagementSaga),
    fork(allProjectsSaga),
    fork(getRolesSaga),
    fork(saveUserRoleSaga),
    fork(projectManagementSaga),
    fork(saveProjectManagementSaga),
    fork(updateProjectManagementSaga),
    fork(getSettingTableDataSaga),
    fork(deleteSettingTableDataSaga),
    fork(updateSettingTableDataSaga),
    fork(mappedProjectManagementSaga),
    fork(getUnRegisteredUsersSaga),
    fork(projectTasksSaga),
    fork(assignedProjectsSaga),
    fork(detailedTimeSheetSaga),
    fork(timeSheetReporteeSaga),
    fork(getTimesheetProjectsSaga),
    fork(saveClientAdminFileSaga),
    fork(saveProjectAdminFileSaga),
    fork(deleteProjectManagementData),
    fork(getTimesheetReportsSaga),
    fork(getAllocationChangeReportSaga),
    fork(getAllocationChangeReportDataSaga),
    fork(getMissingTimesheetsaga),
    fork(getNotificationssaga),
    fork(updateNotificationsaga),
    fork(syncAzureDataSaga),
    fork(updateNotificationForUserSaga),
    fork(getProjectByIdSaga),
    fork(getAllClientsInvoiceSaga),
    fork(saveInvoiceTrackerDataSaga),
    fork(getInvoiceSaga),
    fork(updateInvoiceSaga),
    fork(deleteInvoiceSaga),
    fork(usersProjectAllocationSaga),
    fork(getChangesHistorySaga),
    fork(getInvoicedMonthsSaga),
    fork(syncZohoPeopleSaga),
    fork(getZohoPeopleDetailsSaga),
    fork(skillsDataSaga),
    fork(downloadProjectAdminSaga),
    fork(getMultipleFileViewSaga),
    fork(downloadMultipleFileViewSaga),
    fork(deleteMultipleFileViewSaga),
    fork(holidayListSaga),
    fork(updateHolidaySaga),
    fork(deleteHolidaySaga),
    fork(addHolidaySaga),
    fork(cloneHolidaySaga),
    fork(setupWFODaysSaga),
    fork(updateWFODaysSaga),
    fork(addCustomWfoDaysSaga),
    fork(getCustomWfoDaysSaga),
    fork(cloneWfoDaysSaga),
    fork(viewAttendanceSaga),
    fork(allLeaveDetailsSaga),
    fork(getAllocationChangeReportByEmployeeSaga),
    fork(getAllocationChangeReportByEmployeeDataForExcelSaga),
    fork(deleteCustomWfoDaySaga),
    fork(addSessionSaga),
    fork(updateSessionSaga),
    fork(allSessionsSaga),
    fork(deleteSessionSaga),
    fork(participantsDataSaga),
    fork(updateParticipantRequestSaga),
    fork(allSessionsSaga),
    fork(registerUserSessionSaga),
    fork(getUserDetailsForTrainingAndSessionSaga),
    fork(updateUserRegistrationSaga),
    fork(sessionUserHistorySaga),
    fork(getSessionFeedbackQuestionsListSaga),
    fork(updateAttendanceRequestSaga),
    fork(participantsDetailsBySessionSaga),
    fork(submitSessionFeedbackDetailsSaga),
    fork(GetSessionFeedbackHeaderSaga),
    fork(sendMissingTimesheetReminderManuallySaga),
    fork(getAttendanceForExcelSaga),
    fork(clonePreviousWeekTimesheetSaga),
  ]);
}
