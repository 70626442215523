export const SettingDataType = {
  GET_USER_ROLE_DATA: "GET_USER_ROLE_DATA",
  SET_USER_ROLE_DATA: "SET_USER_ROLE_DATA",
  SAVE_USER_ROLE_DATA: "SAVE_USER_ROLE_DATA",
  GET_SETTING_TABLE_DATA: 'GET_SETTING_TABLE_DATA',
  SET_SETTING_TABLE_DATA: 'SET_SETTING_TABLE_DATA',
  DELETE_SETTING_TABLE_DATA: 'DELETE_SETTING_TABLE_DATA',
  UPDATE_SETTING_TABLE_DATA: 'UPDATE_SETTING_TABLE_DATA',
  SYNC_AZURE_DATA: 'SYNC_AZURE_DATA',
  UPDATE_NOTIFICATION_FOR_USER: 'UPDATE_NOTIFICATION_FOR_USER',
  SYNC_ZOHO_PEOPLE: 'SYNC_ZOHO_PEOPLE',
  GET_ZOHO_PEOPLE_DETAILS: 'GET_ZOHO_PEOPLE_DETAILS',
  SET_ZOHO_PEOPLE_DETAILS: 'SET_ZOHO_PEOPLE_DETAILS',
  GET_HOLIDAY_LIST: 'GET_HOLIDAY_LIST',
  SET_HOLIDAY_LIST: 'SET_HOLIDAY_LIST',
  ADD_HOLIDAY: 'ADD_HOLIDAY',
  UPDATE_HOLIDAY: 'UPDATE_HOLIDAY',
  DELETE_HOLIDAY: 'DELETE_HOLIDAY',
  CLONE_HOLIDAY: 'CLONE_HOLIDAY',
  GET_WFO_LIST: 'GET_WFO_LIST',
  SET_WFO_LIST: 'SET_WFO_LIST',
  UPDATE_WFO_LIST: 'UPDATE_WFO_LIST',
  ADD_CUSTOM_WFO: 'ADD_CUSTOM_WFO',
  GET_CUSTOM_WFO: 'GET_CUSTOM_WFO',
  SET_CUSTOM_WFO: 'SET_CUSTOM_WFO',
  CLONE_WFO_DAYS: 'CLONE_WFO_DAYS',
  DELETE_CUSTOM_WFO_DAY: 'DELETE_CUSTOM_WFO_DAY'
};

export const getUserRoleData = () => ({
  type: SettingDataType.GET_USER_ROLE_DATA,
});

export const saveUserRoleData = (data) => ({
  type: SettingDataType.SAVE_USER_ROLE_DATA,
  payload: data,
});

export const setUserRoleData = (data) => ({
  type: SettingDataType.SET_USER_ROLE_DATA,
  payload: data,
});

export const getSettingTableData = ()=>({
  type: SettingDataType.GET_SETTING_TABLE_DATA,
})

export const setSettingTableData = (data)=>({
  type: SettingDataType.SET_SETTING_TABLE_DATA,
  payload:data
})

export const deleteSettingTableData = (userId)=>({
  type: SettingDataType.DELETE_SETTING_TABLE_DATA,
  payload:userId
})

export const updateSettingTableData = (roleId, userId,projectId)=>({
  type: SettingDataType.UPDATE_SETTING_TABLE_DATA,
  payload:{userId: userId, roleId:roleId, projectId:projectId}
})

export const syncAzureData = ()=>({
  type: SettingDataType.SYNC_AZURE_DATA
})

export const updateNotificationForUser = (userId)=>({
  type: SettingDataType.UPDATE_NOTIFICATION_FOR_USER,
  payload: {userId: userId}
})

export const syncZohoPeople = () => ({
  type: SettingDataType.SYNC_ZOHO_PEOPLE
})

export const getZohoPeopleDetails = (employeeId) => ({
  type: SettingDataType.GET_ZOHO_PEOPLE_DETAILS,
  payload: employeeId 
})

export const setZohoPeopleDetails = (data) => ({
  type: SettingDataType.SET_ZOHO_PEOPLE_DETAILS,
  payload: data
})

export const getHolidayList = (data) => ({
  type: SettingDataType.GET_HOLIDAY_LIST,
  payload: data
})

export const setHolidayList = (data) => ({
  type: SettingDataType.SET_HOLIDAY_LIST,
  payload:data
})

export const addHoliday = (data)=>({
  type: SettingDataType.ADD_HOLIDAY,
  payload: data
})

export const cloneHoliday = (data)=>({
  type: SettingDataType.CLONE_HOLIDAY,
  payload: data
})

export const updateHoliday = (data)=>({
  type: SettingDataType.UPDATE_HOLIDAY,
  payload: data
})

export const deleteHoliday = (data)=>({
  type: SettingDataType.DELETE_HOLIDAY,
  payload: data
});
export const getWfoList =(data)=>({
  type:SettingDataType.GET_WFO_LIST,
  payload:data
})

export const setWfoList =(data)=>({
  type: SettingDataType.SET_WFO_LIST,
  payload:data
})

export const updateWfoDays = (data)=>({
  type:SettingDataType.UPDATE_WFO_LIST,
  payload:data
})

export const addCustomWfo = (data)=>({
  type:SettingDataType.ADD_CUSTOM_WFO,
  payload:data
})

export const getCustomWfo = (data)=>({
  type:SettingDataType.GET_CUSTOM_WFO,
  payload:data
})
export const setCustomWfo =(data)=>({
  type:SettingDataType.SET_CUSTOM_WFO,
  payload:data
})
export const cloneWfoDays= (data)=>({
  type:SettingDataType.CLONE_WFO_DAYS,
  payload:data
})

export const deleteCustomWfoDay = (data)=>({
  type: SettingDataType.DELETE_CUSTOM_WFO_DAY,
  payload: data
});