import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAllUserDetails,
  getNotifications,
  getSettingTableData,
  getUserRoleData,
  syncAzureData,
  syncZohoPeople,
  getHolidayList,
  getWfoList,
  getCustomWfo
} from "../../redux/actions";
import "./settings.css";
import Button from "@mui/material/Button";
import { SettingsTable } from "./settingsTable";
import { searchIcon } from "../../common/icons";
import { NotificationTable } from "../Notifications/notificationTable";
import moment from "moment";
import { getMappedProjectManagementData } from "../../redux/actions/project-management";
import { HolidayListTable } from "./holidayListTable";
import {SetupWFODays} from "./setupWFODays";

export function Settings({
  tabName
}) {
  const usersData = useSelector(({ MODULES }) => MODULES.settingTableData);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [userData, setUserData] = useState([]);
  const [azureSyncedBy, setAzureSyncedBy] = useState(null);
  const [azureSyncedDate, setAzureSyncedDate] = useState(null);
  const [zohoSyncedBy, setZohoSyncedBy] = useState(null);
  const [zohoSyncedDate, setZohoSyncedDate] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [searchData, setSearchData] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const dispatch = useDispatch();
  const { unRegisteredUserDetails } = useSelector(({ USER }) => USER);
  const { mappedProjectManagementData  } = useSelector(({ MODULES }) => MODULES);
  const { userRole } = useSelector(({ MODULES }) => MODULES);
  const {vTrackLoader} = useSelector(({APP_STATE}) => APP_STATE);
  const { holidayList } = useSelector(({ MODULES }) => MODULES);

  const setSearchDataHelper = (e) => {
      setSearchData(e.target.value)
  };

  useEffect(() => {
    if(tabName === 'MANAGE USER ROLE'){
      dispatch(getAllUserDetails());
      // dispatch(getUnregisteredUserDetails());
      dispatch(getUserRoleData());
      dispatch(getSettingTableData());
      dispatch(getMappedProjectManagementData());
    } else if(tabName === 'MANAGE NOTIFICATIONS'){
      dispatch(getNotifications());
      }
      else if(tabName === 'HOLIDAY LIST'){
        dispatch(getHolidayList({year:new Date().getFullYear()}));
      }
      else if(tabName === 'SETUP WFO DAYS'){
        dispatch(getWfoList({year:currentYear}));
        dispatch(getCustomWfo({year:currentYear}))
        
      }
  }, [tabName]);

  useEffect(() => {
    if (unRegisteredUserDetails) {
      setUserData(unRegisteredUserDetails);
    }
  }, [unRegisteredUserDetails]);

  useEffect(() => {
    if (userRole) {
      setRolesData(userRole);
    }
  }, [userRole]);

  useEffect(() => {
    if(usersData && usersData.length){
      setAzureSyncedBy(usersData[0]["azureSyncedBy"]);
      setAzureSyncedDate(usersData[0]["azureSyncedDate"]);
      setZohoSyncedBy(usersData[0]["zohoSyncedBy"]);
      setZohoSyncedDate(usersData[0]["zohoSyncedDate"]);
    }
  }, [usersData])

  // React.useEffect(() => {
  //   if((mappedProjectManagementData===null || mappedProjectManagementData.length===0) && headingName === Modules.PROJECT_MANAGEMENT) setNoDataFound(true);
  //   else setNoDataFound(false);
  // }, [mappedProjectManagementData, headingName])

  let projectTab=[];
  let projectTabs=[];
  if (mappedProjectManagementData){
    mappedProjectManagementData.forEach((client) => {
      client.projects.forEach((project)=>{
        projectTab.push({
          projectName : project.projectName,
          projectId: project.projectId
        })
      })
    });

    projectTabs=projectTab.sort(function(a,b){return (a.projectName).localeCompare(b.projectName)})
  }

  const onClickSyncAzure = () =>{
    dispatch(syncAzureData());
  }

  const onClickZohoPeople = () => {
    dispatch(syncZohoPeople());
  }

  if(tabName === 'MANAGE USER ROLE'){
    return (
      <>
        <div className="settingsWrapper">
        <div className="searchHeader">
          <div className="searchWrapper">
            <img src={searchIcon} className="searchIcon" alt="" />
            <input
              className="searchBox"
              type="search"
              placeholder="Search by Employee or Role"
              onChange={setSearchDataHelper}
            />
          </div>
          <div>
            <div className="buttonClass">
              <div className="syncTextContainer">
                <div className="syncText">
                  {azureSyncedBy && <div>Last Synced By: {azureSyncedBy}</div>}
                </div>
                <div className="syncText">
                  {azureSyncedDate && <div>Last Synced Date: {moment(azureSyncedDate).format("DD-MM-yyyy LTS")}</div>}
                </div>
              </div>
              <Button 
                variant="contained" 
                onClick={onClickSyncAzure} 
                disabled = {vTrackLoader}
                className="addUserBtn">
                  Sync Azure AD With vTrack
              </Button>
            </div>

            <div className="buttonClass">
              <div className="syncTextContainer">
                <div className="syncText">
                  {zohoSyncedBy && <div>Last Synced By: {zohoSyncedBy}</div>}
                </div>
                <div className="syncText">
                  {zohoSyncedDate && <div>Last Synced Date: {moment(zohoSyncedDate).format("DD-MM-yyyy LTS")}</div>}
                </div>
              </div>
              <Button
                variant="contained" 
                onClick={onClickZohoPeople} 
                disabled = {vTrackLoader}
                className="addUserBtn">
                Sync Zoho People With vTrack
              </Button>
            </div>
          </div>
        </div>
        <div className="bottomContainer">
          <SettingsTable rolesData={rolesData} searchData={searchData} projectData={projectTabs}/> 
        </div>
        </div>
      </> 
    )
  } 
  else if(tabName === 'HOLIDAY LIST'){
    // if(holidayList)
    return <HolidayListTable/>;
  }
  else if(tabName === 'SETUP WFO DAYS'){
    return <SetupWFODays/>;
  }
  else {
    return <NotificationTable />;
  }

}
