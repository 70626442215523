import { HttpMethod } from "../../common/constants/http-requests";
import { httpRequest } from "../../common/utils/http-request";
import { ROLES_API, USER_DETAILS, ZOHO_API } from "../api/services";

export const getUserRoles = () =>
  httpRequest({
    url: `${ROLES_API}/get-roles`,
    method: HttpMethod.GET,
  });

export const saveUserRoles = (data) =>
  httpRequest({
    url: `${ROLES_API}/${data.roleID}/add-user-role`,
    method: HttpMethod.POST,
    data: data.data,
  });

  export const getSettingUserData = () =>
  httpRequest({
    url: `${ROLES_API}/get-users`,
    method: HttpMethod.GET,
  });

  export const deleteSettingUserData = (userId) =>
  httpRequest({
    url: `${ROLES_API}/${userId}/delete-user`,
    method: HttpMethod.DELETE,
  });

  export const updateSettingUserRole = (userId, roleId,projectId) =>//, project_Id
  httpRequest({
    url: `${ROLES_API}/${userId}/${roleId}/${projectId}/update-user-role`,///${project_Id}/
    method: HttpMethod.PUT,
  });

  export const syncAzureData = () =>
  httpRequest({
    url: `${USER_DETAILS}/sync-users`,
    method: HttpMethod.GET
  })

  export const updateNotificationForUser = (userId) => 
  httpRequest({
    url: `${USER_DETAILS}/${userId}/update-notification-for-user`,
    method: HttpMethod.PUT
  })

  export const syncZohoPeople = () => 
  httpRequest({
    url: `${ZOHO_API}/sync-zoho-people`,
    method: HttpMethod.GET
  })

  export const getZohoPeopleDetails = (employeeId) =>
  httpRequest({
    url: `${ZOHO_API}/Employee/${employeeId}/ZohoDetails`,
    method: HttpMethod.GET
  })